import React from "react";
import ContactTwo from "../../components/Contact/ContactTwo";
import Breadcrumb from "../../components/Breadcrumb";
import CTA from "../home/CtaSection";

const ContactMain = () => {
  return (
    <main>
      <Breadcrumb pageTitle="Contact" />

      <ContactTwo />

      <CTA />
    </main>
  );
};

export default ContactMain;
