import React from "react";
import { Link } from "react-router-dom";

import Logo from "../../assets/img/logo/logo.png";
import footerBG from "../../assets/img/footer/footer-bg.jpg";

const Footer = (props) => {
  const { footerLogo, footerClass } = props;
  return (
    <footer>
      <div
        className={footerClass ? footerClass : "footer__area footer__plr"}
        style={{ backgroundImage: `url(${footerBG})` }}
      >
        <div className="container-fluid">
          <div className="row" style={{ justifyContent: "center" }}>
            <div
              className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-7 mb-70 wow animate__fadeInUp"
              data-wow-duration="1.1s"
            >
              <div className="footer__widget footer-col-1">
                <div className="footer__logo">
                  <Link to="/">
                    <img
                      src={footerLogo ? footerLogo : Logo}
                      alt=""
                      style={{ width: "110px" }}
                    />
                    <div
                      style={{ color: "white", fontSize: 20, fontWeight: 600 }}
                    >
                      Wakeel 360
                    </div>
                    <div style={{ color: "white", opacity: 0.8 }}>
                      Software Development Company
                    </div>
                  </Link>
                </div>
                <div className="footer__content">
                  <div className="footer__text">
                    <p>
                      We leverage a proven methodology honed through years of
                      industry experience, blending cutting-edge techniques with
                      time-tested best practices to deliver transformative
                      digital solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-3 col-xl-2 col-lg-6 col-md-6 col-sm-5 mb-70 wow animate__fadeInUp"
              data-wow-duration="1.1s"
            >
              <div className="footer__widget footer-col-2">
                <h4 className="footer__title">
                  <Link to="/service">Services</Link>
                </h4>
                <div className="footer__menu-list">
                  <ul>
                    <li>
                      <a href="#">Websites</a>
                    </li>
                    <li>
                      <a href="#">Web Apps</a>
                    </li>
                    <li>
                      <a href="#">Mobile Apps</a>
                    </li>
                    <li>
                      <a href="#">Software QA</a>
                    </li>
                    <li>
                      <a href="#">Blockchain</a>
                    </li>
                    <li>
                      <a href="#">AI/ML</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div
              className="col-xxl-3 col-xl-2 col-lg-6 col-md-6 col-sm-5 mb-70 wow animate__fadeInUp"
              data-wow-duration="1.1s"
            >
              <div className="footer__widget footer-col-3">
                <h4 className="footer__title">
                  <a href="#">Support Link</a>
                </h4>
                <div className="footer__menu-list">
                  <ul>
                    <li>
                      <a href="#">Web Design</a>
                    </li>
                    <li>
                      <a href="#">Development</a>
                    </li>
                    <li>
                      <a href="#">WordPress</a>
                    </li>
                    <li>
                      <a href="#">Online Marketing</a>
                    </li>
                    <li>
                      <a href="#">Content</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div
              className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-7 mb-70 wow animate__fadeInUp"
              data-wow-duration="1.1s"
            >
              <div className="footer__widget footer-col-4">
                <h4 className="footer__title">
                  <a href="#">Visit Us</a>
                </h4>
                <div className="footer__content">
                  <p>Office 1, 691 Stratford Road Birmingham, B11 4DX UK</p>
                </div>

                <div
                  className="footer__number-wrapper d-flex align-items-center"
                  style={{ marginBottom: 20 }}
                >
                  <div className="footer__number-icon">
                    <span>
                      <i className="fal fa-phone-alt"></i>
                    </span>
                  </div>
                  <div className="footer__number-info">
                    <span>Reach Out to Our Experts</span>
                    <a href="tel:+441212212206">+44 1212212206</a>
                  </div>
                </div>
                {/* <div className="footer__input">
                  <form action="#">
                    <input type="email" placeholder="Your email" />
                    <button className="subscribe-btn">Subscribe </button>
                  </form>
                </div> 
                <div className="footer__social-box">
                  <span>Social Media:</span> 
                  <a href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-google-plus-g"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-pinterest-p"></i>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright__area copyright__plr black-bg-3">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div
              className="col-md-6 wow animate__slideInUp"
              data-wow-duration="1.1s"
            >
              <div className="copyright__text">
                <span>Copyright 2024 © wakeel360. All Right Reserved</span>
              </div>
            </div>
            <div
              className="col-md-6 wow animate__fadeInUp"
              data-wow-duration="1.1s"
            >
              <div className="copyright__item text-start text-md-end">
                <span>
                  <a href="/">Home</a>
                </span>
                <span>
                  <a href="/about-us">About</a>
                </span>
                <span>
                  <a href="/service-1">Services</a>
                </span>
                <span>
                  <a href="/contact">Contact</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
