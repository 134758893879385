import React, { useState } from "react";

import shapeImg1 from "../../assets/img/contact/ct-shape-1.png";
import shapeImg2 from "../../assets/img/contact/ct-shape-2.png";
import shapeImg3 from "../../assets/img/contact/ct-shape-3.png";
import shapeImg4 from "../../assets/img/contact/ct-shape-4.png";
import { useAlert } from "react-alert";
import emailjs from "@emailjs/browser";
emailjs.init({ publicKey: "34g8DKDcniTYDl-1a" });

const ContactTwo = (props) => {
  const { itemClass } = props;
  const alert = useAlert();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  function sendEmail(e) {
    e.preventDefault();

    alert("Thank you for your message! We will get back to you shortly.");
    return;

    // emailjs.sendForm("", "", e.target, "").then(
    //   (result) => {
    //     console.log(result.text);
    //   },
    //   (error) => {
    //     console.log(error.text);
    //   }
    // );

    e.target.reset();
  }
  const handleContact = () => {
    alert.show("We have received your message. We'll be in touch shortly.");
    emailjs.send("service_mddalzz", "template_lwcrdej", {
      from_name: name,
      reply_to: email,
      message: message,
    });
    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <div
      className={
        itemClass
          ? itemClass
          : "contact__area contact__plr-2 mt-100 mb-100 p-relative fix"
      }
    >
      <div className="contact__shape-1 d-none d-lg-block">
        <img src={shapeImg1} alt="" />
      </div>
      <div className="contact__shape-2 d-none d-md-block">
        <img src={shapeImg2} alt="" />
      </div>
      <div className="contact__shape-3 d-none d-md-block">
        <img src={shapeImg3} alt="" />
      </div>
      <div className="contact__shape-4 d-none d-md-block">
        <img src={shapeImg4} alt="" />
      </div>
      <div className="contact__border">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-8 col-lg-7">
              <div className="contact__section-title pb-10">
                <h4
                  className="section-subtitle char-anim"
                  style={{ backgroundColor: "#ecf4ff" }}
                >
                  Contact Us
                </h4>
                <h3 className="section-title char-anim">
                  Unlock your digital potential - let's collaborate now!
                </h3>
              </div>
              <div className="contact__text">
                <p className="char-anim-2"></p>
              </div>
              <div
                className="contact__form wow animate__fadeInUp"
                data-wow-duration="1.1s"
              >
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="postbox__contact-input">
                        <input
                          type="text"
                          placeholder="Your Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="postbox__contact-input">
                        <input
                          type="email"
                          placeholder="Your Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="postbox__contact-textarea">
                        <textarea
                          placeholder="Message"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div
                className="contact__button wow animate__fadeInUp"
                data-wow-duration="1.1s"
                onClick={handleContact}
              >
                <a className="main-btn-sm  tp-btn-hover alt-color" href="#">
                  <span style={{ color: "white" }}>Send</span>
                  <b></b>
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="contact__left-side">
                <div
                  className="contact__item d-flex align-items-center wow animate__fadeInUp"
                  data-wow-duration="1.1s"
                >
                  <div className="contact__icon">
                    <span>
                      <a href="#">
                        <i className="fal fa-phone-alt contact-right-icon"></i>
                      </a>
                    </span>
                  </div>
                  <div className="contact__content">
                    <h4 className="contact__title-sm contact-right-title">
                      Call Now
                    </h4>
                    <span>
                      <a
                        href="tel:+441212212206"
                        className="contact-right-title"
                      >
                        +44 1212212206
                      </a>
                    </span>
                  </div>
                </div>
                <div
                  className="contact__item d-flex align-items-center wow animate__fadeInUp"
                  data-wow-duration="1.1s"
                >
                  <div className="contact__icon">
                    <span>
                      <a href="#">
                        <i className="fal fa-envelope-open-text"></i>
                      </a>
                    </span>
                  </div>
                  <div className="contact__content">
                    <h4 className="contact__title-sm contact-right-title">
                      Email
                    </h4>
                    <span>
                      <a
                        href="mailto:contact@wakeel360.co.uk"
                        className="contact-right-title"
                      >
                        contact@wakeel360.co.uk
                      </a>
                    </span>
                  </div>
                </div>
                <div
                  className="contact__item d-flex align-items-center wow animate__fadeInUp"
                  data-wow-duration="1.1s"
                >
                  <div className="contact__icon">
                    <span>
                      <a href="#">
                        <i className="fal fa-map-marker-check"></i>
                      </a>
                    </span>
                  </div>
                  <div className="contact__content">
                    <h4 className="contact__title-sm contact-right-title">
                      Visit Us
                    </h4>
                    <span>
                      <a className="contact-right-title">
                        Office 1, 691 Stratford Road Birmingham, B11 4DX UK
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactTwo;
