import React from "react";
import { Link } from "react-router-dom";

import SectionTitle from "../../components/SectionTitle";
import SingleService from "../../components/Service";

import shapeImg1 from "../../assets/img/service/sv-shape-1.png";
import shapeImg2 from "../../assets/img/service/sv-shape-2.png";

import serviceIconBg1 from "../../assets/img/service/app-settings.png";
import web from "../../assets/img/service/web.png";
import sqa from "../../assets/img/service/quality-assurance.png";
import blockchain from "../../assets/img/service/block.png";
import deepLearning from "../../assets/img/service/deep-learning.png";
import serviceIconBg2 from "../../assets/img/service/sv-icon-shape-2.png";
import serviceIconBg3 from "../../assets/img/service/sv-icon-shape-3.png";
import serviceIconBg4 from "../../assets/img/service/sv-icon-shape-4.png";
import serviceIconBg5 from "../../assets/img/service/sv-icon-shape-5.png";
import serviceIconBg6 from "../../assets/img/service/sv-icon-shape-6.png";

const Service = () => {
  return (
    <div className="service__area service__space service__mlr grey-bg pt-195 pb-70 p-relative">
      <div className="service__shape-1">
        <img src={shapeImg1} alt="" />
      </div>
      <div className="service__shape-2">
        <img src={shapeImg2} alt="" />
      </div>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div
            className="col-xl-6 col-lg-6 wow animate__fadeInUp"
            data-wow-duration="1.1s"
          >
            <SectionTitle
              sectionClass="service__section-box text-center pb-35 section-title-fixed-width"
              subTitle="Our Services"
              Title="Comprehensive Suite of Custom Software Development Services"
            />
          </div>
        </div>
        <div className="row">
          <div
            className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration="1.1s"
          >
            <SingleService
              itemClass="service__item service__border-1 p-relative fix"
              titleClass="service__title title-color-1"
              btnClass="service__link service__link-color-1"
              serviceIconBg={web}
              Title="Web Development"
              Description="Our skilled web development team crafts visually stunning, user-centric websites that drive engagement and achieve your online goals. From custom design to seamless functionality, we deliver innovative web solutions tailored to your business needs."
              btnText="Discover More"
              service_id="web_development"
              />
          </div>
          <div
            className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration="1.1s"
            >
            <SingleService
              itemClass="service__item service__border-3 p-relative fix"
              titleClass="service__title title-color-3"
              btnClass="service__link service__link-color-3"
              serviceIconBg={serviceIconBg1}
              Title="Mobile App Development"
              Description="To expand your reach and engage your customers on the go, our custom mobile app development services build cutting-edge iOS and Android applications that provide intuitive experiences and deliver tangible business value"
              btnText="Discover More"
              service_id="mobile_development"
              />
          </div>
          <div
            className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration="1.1s"
            >
            <SingleService
              itemClass="service__item service__border-4 p-relative fix"
              titleClass="service__title title-color-4"
              btnClass="service__link service__link-color-4"
              serviceIconBg={sqa}
              Title="Software Quality Assurance"
              Description="To ensure the reliability, performance, and security of your software, our QA services employ rigorous testing methodologies to identify and resolve issues, giving you the confidence to deploy flawless digital solutions."
              btnText="Discover More"
              service_id="software_quality_assurance"
              />
          </div>
          <div
            className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration="1.1s"
            >
            <SingleService
              itemClass="service__item service__border-5 p-relative fix"
              titleClass="service__title title-color-5"
              btnClass="service__link service__link-color-5"
              serviceIconBg={blockchain}
              Title="Blockchain Development"
              Description="To unlock the transformation power of blockchain technology, our specialized development services leverage deep knowledge of distributed ledger systems, smart contracts, and decentralized applications, helping you stay ahead of the curve."
              btnText="Discover More"
              service_id="blockchain_development"
              />
          </div>
          <div
            className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration="1.1s"
            >
            <SingleService
              itemClass="service__item service__border-6 p-relative fix"
              titleClass="service__title title-color-6"
              btnClass="service__link service__link-color-6"
              serviceIconBg={deepLearning}
              Title="AI/ML Development"
              Description="To elevate your business with advanced AI and Machine Learning capabilities, our skilled engineers develop intelligent, data-driven software solutions that automate processes, generate insights, and deliver exceptional customer experiences."
              btnText="Discover More"
              service_id="ai_ml_development"
              />
          </div>
          <div
            className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration="1.1s"
            >
            <SingleService
              itemClass="service__item service__border-1 p-relative fix"
              titleClass="service__title title-color-1"
              btnClass="service__link service__link-color-1"
              serviceIconBg={web}
              Title="Website Development"
              Description="Our skilled web development team crafts visually stunning, user-centric websites that drive engagement and achieve your online goals. From custom design to seamless functionality, we deliver innovative web solutions tailored to your business needs."
              btnText="Discover More"
              service_id="website_development"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
